import React, { useState, useEffect, useMemo, Suspense } from "react";
import {
  itIT,
  DataGridPro,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import LaunchIcon from "@mui/icons-material/Launch";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {
  Button,
  Dialog,
  DialogActions,
  Stack,
  TextField,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  Fab,
  Tooltip,
  FormControlLabel,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Drawer,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DynamicFormComponentModal from "../../components/modalDynamicform";
import CustomCheckbox from "components/customInputs/CheckboxCST";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DynamicWizard from "../../components/dynamic-wizard";
import { Row, Col } from "reactstrap";
import useMounted from "react-use-mounted";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import BuildIcon from "@mui/icons-material/Build";
import { CircularProgress } from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import KanbanIcon from "@mui/icons-material/ViewAgenda";
import ListIcon from "@mui/icons-material/List";
import { thunks } from "../../thunks/general";

export const List = ({ setViewMode }) => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [state, setState] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [isOpen, setIsOpen] = useState(false); // Define state for Drawer visibility
  // Stato per il modello di paginazione con pageSize predefinito a 25
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25, // Numero di righe per pagina di default
    page: 0, // Pagina iniziale
  });


  const [searchTerm, setSearchTerm] = useState("");


  const [rowCount, setRowCount] = useState(0); // Numero totale di record

  const general = useSelector((state) => state.general);
  const handleOpenDrawer = () => {
    setIsOpen(true);
  };

  // Handler to close the Drawer
  const onClose = () => {
    setIsOpen(false);
  };
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    rowId: null,
    status: null,
  });

  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState({
    open: false,
    rowId: null,
    status: null,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkboxSelected, setCheckboxSelected] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const currentFormType = location.pathname.split("/")[2];

  const isQueryNewly = location.pathname.split("/")[4];
  const hasFilters = location.pathname.split("/")[3];

  if (hasFilters == 'filter') {
    const filterName = location.pathname.split("/")[4];
    const filterValue = location.pathname.split("/")[5];

  }

  const dispatch = useDispatch();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [modificaText, setModificaText] = useState("");
  const [checkboxState, setCheckboxState] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });

  const apiRef = useGridApiRef();
  const [rowsData, setRowsData] = useState([]);
  const [loading, setLoading] = useState(false); // P
  const [formFieldsColumn, setFormFieldsColumn] = useState([
    { field: "", headerName: "", width: 100 },
  ]);
  const [columnsData, setColumnsData] = useState([
    { field: "", headerName: "", width: 100 },
  ]);
  const [visibilityFields, setVisibilityFields] = useState({});
  const [error, setError] = useState(null);

  const [localLoader, setLocalLoader] = useState(false);
  const isMounted = useMounted();

  const handleOpenDialog = (rowId, actionType) => {
    if (actionType === "delete") {
      setDeleteConfirmDialog({ open: true, rowId });
    } else {
      setConfirmDialog({ open: true, rowId });
    }
  };

  const handleEditDialogOpen = () => {
    setEditDialogOpen(true);
  };
  useEffect(() => {
    setPaginationModel({
      pageSize: 25, // Reset al valore predefinito
      page: 0,      // Reset alla prima pagina
    });
  }, [currentFormType]); // Effettua il reset quando cambia location

  const formType = location.pathname.split("/")[2];
  async function getJsonField() {
    try {
      setLoading(true);

      setColumnsData([
        {
          field: "placeholder1",
          headerName: "Caricamento...",
          width: 200,
          renderHeader: () => (
            <>
              <CircularProgress
                size={20}
                style={{ marginRight: 5, verticalAlign: "middle" }}
              />
              <span>Caricamento...</span>
            </>
          ),
        },
        {
          field: "placeholder2",
          headerName: "Caricamento...",
          width: 200,
          renderHeader: () => (
            <>
              <CircularProgress
                size={20}
                style={{ marginRight: 5, verticalAlign: "middle" }}
              />
              <span>Caricamento...</span>
            </>
          ),
        },
        {
          field: "placeholder3",
          headerName: "Caricamento...",
          width: 200,
          renderHeader: () => (
            <>
              <CircularProgress
                size={20}
                style={{ marginRight: 5, verticalAlign: "middle" }}
              />
              <span>Caricamento...</span>
            </>
          ),
        },
      ]);

      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };

      let apiUrl = `${process.env.REACT_APP_APP_ENDPOINT}api/auth/getRecords/${currentFormType}`;

      if (isQueryNewly === 'nuovi' || isQueryNewly === 'nuove' || isQueryNewly === 'nuovo') {
        apiUrl += '?new=true';
      } else if (hasFilters === 'filter') {
        const filterName = location.pathname.split("/")[4];
        const filterValue = location.pathname.split("/")[5];
        apiUrl += `?${filterName}=${filterValue}`;
      }


      if (searchTerm) {
        // Verifica se ci sono già parametri nella URL
        const separator = apiUrl.includes('?') ? '&' : '?';
        apiUrl += `${separator}search=${searchTerm}`;
      }


      // Effettua la chiamata API per ottenere i record con la paginazione
      const responseRecords = await axios.get(apiUrl, {
        headers: headers,
        params: {
          page: paginationModel.page + 1, // Laravel accetta l'indice della pagina a partire da 1
          perPage: paginationModel.pageSize, // Numero di record per pagina
        },
      });

      const responseFields = await axios.get(
        `${process.env.REACT_APP_APP_ENDPOINT}api/auth/getFieldsForObjectTable/${currentFormType}`,
        { headers: headers }
      );
      if (Array.isArray(responseFields.data)) {
        const formFieldColumn = responseFields.data.map((field) => ({
          field: field.fieldName,
          headerName: field.label, // Usa la label per il nome della colonna
          width: 200,
          renderCell: (row) => (
            <>
              <div>
                <div>
                  {row.row[field.fieldName] ? (
                    <>
                      {field.fieldName === "title" || field.fieldName === "name" ? (
                        <Link to={`/adminList/${currentFormType}/show/${row.id}`}>
                          {row.row[field.fieldName]}
                        </Link>
                      ) : (
                        row.row[field.fieldName]
                      )}
                    </>
                  ) : (
                    "--"
                  )}
                </div>
              </div>
            </>
          ),
        }));

        setColumnsData(formFieldColumn); // Imposta le colonne con le etichette corrette
      }

      const records = responseRecords.data.data; // I dati paginati
      setRowsData(records); // Imposta i dati nel componente
      setRowCount(responseRecords.data.total); // Imposta il numero totale di record

      if (records.length) {
        const formFieldColumn = Object.keys(records[0]).map((fieldName) => ({
          field: fieldName,
          headerName: fieldName.charAt(0).toUpperCase() + fieldName.slice(1),
          width: 200,
          renderCell: (row) => (
            <>
              <div>
                <div>
                  {row.row[fieldName] ? (
                    <>
                      {fieldName === "title" || fieldName === "name" ? (
                        <Link to={`/adminList/${currentFormType}/show/${row.id}`}>
                          {row.row[fieldName]}
                        </Link>
                      ) : (
                        row.row[fieldName]
                      )}
                    </>
                  ) : (
                    "--"
                  )}
                </div>
              </div>
            </>
          ),
        }));

      }
    } catch (error) {
      console.error("Errore nel caricamento dei dati:", error);
      toast.error("Errore durante il caricamento dei dati.");
    } finally {
      setLoading(false); // Imposta lo stato di caricamento a false
    }
  }

  useEffect(() => {
    getJsonField();
  }, [paginationModel, currentFormType, location]); // Richiama la funzione ogni volta che cambiano pagina, dimensione o tipo di form



  const handleOpenConfirmDialog = (rowId, actionType, status) => {
    if (actionType === "delete") {
      setDeleteConfirmDialog({ open: true, rowId });
    } else {
      setConfirmDialog({ open: true, rowId, status });
    }
  };
  const CustomNoRowsOverlay = () => {
    return (
      <Stack
        height="100%"
        alignItems="center"
        justifyContent="center"
        sx={{ typography: "h6", color: "grey.500" }} // Stile del messaggio
      >
        Nessun record disponibile
      </Stack>
    );
  };

  const handleConfirmDelete = async () => {
    try {
      if (!checkboxSelected || checkboxSelected.length === 0) {
        toast.error("Nessuna riga selezionata per l'eliminazione.");
        return;
      }

      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };

      const endpoint =
        process.env.REACT_APP_APP_ENDPOINT +
        `api/auth/delete/` +
        currentFormType;

      // Usa axios.delete con data invece di params
      const response = await axios.delete(endpoint, {
        headers: headers,
        data: { ids: checkboxSelected }, // Passing body data
      });

      if (response.status === 200) {
        // Aggiorna lo stato del componente con i nuovi dati
        const updatedRows = rowsData.filter(
          (row) => !checkboxSelected.includes(row.id)
        );
        setRowsData(updatedRows);

        toast.success("Righe eliminate con successo");
      } else {
        toast.error("Errore durante l'eliminazione.");
      }
    } catch (error) {
      console.error("Errore durante l'eliminazione:", error);
      toast.error("Errore durante l'eliminazione.");
    } finally {
      setDeleteConfirmDialog({ open: false, selectedRowId: [] });
      handleCloseDialog();
    }
  };
  const handleLinkClick = (rowId) => {
    const url = `/asta/${rowId}`;
    window.open(url, "_blank"); // Apre il link in una nuova pagina
  };
  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setCheckboxState({ checkbox1: false, checkbox2: false, checkbox3: false });
    setModificaText("");
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setIsModalOpen(false);
    setConfirmDialog({ open: false, rowId: null, newState: null });
    setDeleteConfirmDialog({ open: false, rowId: null, newState: null });
  };
  const handleCheckboxChange = (event) => {
    setCheckboxState({
      ...checkboxState,
      [event.target.name]: event.target.checked,
    });
  };
  const handleSelectionChange = (newSelection) => {
    setCheckboxSelected(newSelection);
  };
  const handleModificaTextChange = (event) => {
    setModificaText(event.target.value);
  };

  const handleEditDialogSubmit = async () => {
    if (!modificaText.trim()) {
      toast.error("Il campo di testo per la modifica è obbligatorio.");
      return;
    }
    // Qui invii i dati al server, ad esempio:
    try {
      const response = await axios.post("Your_API_Endpoint", {
        checkboxes: checkboxState,
        modificaText: modificaText,
      });
      if (response.status === 200) {
        toast.success("Modifica richiesta con successo!");
      } else {
        toast.error("Qualcosa è andato storto.");
      }
    } catch (error) {
      toast.error("Errore durante l'esecuzione dell'azione.");
    }
    handleEditDialogClose();
  };
  const handleOnClick = (rowId) => {
    navigate("/admin/" + currentFormType + "/" + rowId);
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  function StatusCell(props) {
    const { status } = props;

    let color = "default";
    let icon = null;
    let label = "Stato";

    switch (status) {
      case "Approvato":
        color = "green";
        icon = <CheckCircleIcon />;
        label = "Approvato";
        break;
      case "Rifiutato":
        color = "red";
        icon = <CancelIcon />;
        label = "Rifiutato";
        break;
      case "Richiesta Modifica":
        color = "orange";
        icon = <BuildIcon />;
        label = "Modifica";
        break;
      case "Nuovo":
        color = "darkblue";
        icon = <BuildIcon />;
        label = "Nuovo";
        break;
      default:
        label = status;
    }

    return (
      <Button
        startIcon={icon}
        label="status"
        disabled
        variant="contained"
        style={{
          backgroundColor: color,
          color: "white",
          cursor: "default",
          padding: "3px 10px",
          textTransform: "none",
        }}
      >
        {label}
      </Button>
    );
  }

  const getApprovalColumn = () => {
    if (
      (currentFormType !== "immobile" && currentFormType !== "asta") ||
      isSmallDevice
    ) {
      return null;
    }

    const renderButtonsBasedOnStatus = (status, rowId) => {
      if (status !== 1) {
        if (currentFormType == "immobile") {
          return (
            <>
              <Button
                color="success"
                onClick={() =>
                  handleOpenConfirmDialog(rowId, status, "Approvato")
                }
                style={{ marginRight: 8 }}
              >
                <Tooltip title="Approva" arrow>
                  <CheckCircleOutlinedIcon fontSize="small" />
                </Tooltip>
              </Button>
              <Button
                color="error"
                onClick={() =>
                  handleOpenConfirmDialog(rowId, status, "Rifiutato")
                }
              >
                <Tooltip title="Rifiuta">
                  <ClearOutlinedIcon fontSize="small" />
                </Tooltip>
              </Button>
              <Button color="warning" onClick={() => handleEditDialogOpen()}>
                <Tooltip title="Modifica">
                  <ModeEditOutlinedIcon fontSize="small" />
                </Tooltip>
              </Button>

              <a
                to={`/asta/${rowId}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "green",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleLinkClick(rowId);
                }}
              >
                <LaunchIcon style={{ marginLeft: "4px" }} />
              </a>
            </>
          );
        }
      } else {
        return (
          <Button
            color="error"
            onClick={() => handleOpenConfirmDialog(rowId, status, "Sospeso")}
          >
            Sospendi
          </Button>
        );
      }
    };

    return {
      id: "approvalColumn", // Aggiungi una chiave univoca
      field: "actions",
      headerName: "Gestisci Inserzioni",
      sortable: false,
      width: isSmallDevice ? 150 : 300, // Imposta una larghezza fissa
      pinned: "right", // Fissa la colonna a destra
      renderCell: (params) =>
        renderButtonsBasedOnStatus(params.row.status, params.row.id),
      cellClassName: "actions-column",
    };
  };

  const DeleteButtonCustom = ({ checkboxSelected }) => {
    const handleDelete = async () => {
      setDeleteConfirmDialog({
        open: true,
        selectedRows: checkboxSelected,
      });
    };

    return (
      <Button
        color="error"
        onClick={handleDelete}
        disabled={!checkboxSelected || checkboxSelected.length === 0}
        sx={{
          gap: "4px",
          background: "red",
          border: "0px solid",
          color: "#fff",
          padding: "5px 10px",
          minWidth: "unset",
          lineHeight: "20px",
          fontSize: "14px",
          borderRadius: "5px",
          margin: "1px",
          "&:hover": {
            background: "#fff",
            color: "red",
            border: "solid",
          },
          ...(checkboxSelected &&
            checkboxSelected.length === 0 && {
            background: "lightGrey",
            cursor: "not-allowed",
            color: "white",
          }),
        }}
      >
        <DeleteIcon sx={{ fontSize: "large" }} />
      </Button>
    );
  };

  // const columnsWithEditIcon = useMemo(
  //   () =>
  //     [
  //       ...columnsData,
  //       {
  //         field: "status",
  //         width: 150,
  //         renderCell: (params) => (
  //           <StatusCell status={params.row.status} rowId={params.row.id} />
  //         ),
  //       },
  //       getApprovalColumn(),
  //     ].filter(Boolean),
  //   [columnsData, currentFormType]
  // );

  function StatusCellRenderer(params) {
    return <StatusCell status={params.row.status} rowId={params.row.id} />;
  }


  const columnsWithEditIcon = useMemo(
    () =>
      [
        ...columnsData.map((column) => {
          if (column.field === "status") {
            return {
              ...column,
              renderCell: StatusCellRenderer,
            };
          }
          return column;
        }),
        getApprovalColumn(),
      ].filter(Boolean),
    [columnsData, currentFormType]
  );

  const CustomToolbar = () => {
    const location = useLocation();
    const currentFormType = location.pathname.split("/")[2];
    const isAnyRowSelected = checkboxSelected !== 0;

    const ToolbarButtonStyle = {
      background: "#0a97d5",
      border: "solid",
      color: "#fff",
      padding: "5px 15px",
      lineHeight: "20px",
      fontSize: "14px",
      borderRadius: "5px",
      "&:hover": {
        background: "#fff",
        color: "#66C9BA",
        border: "solid",
      },
    };

    const renderHeading = () => {
      return (
        <>
          <Typography variant="h5" sx={{ paddingLeft: 0 }}>
            <AccountBoxIcon
              fontSize="large"
              style={{ verticalAlign: "middle" }}
            />{" "}
            {currentFormType.charAt(0).toUpperCase() + currentFormType.slice(1)}
            {/*
            <Fab
              sx={{ marginLeft: 3 }}
              color="primary"
              onClick={() => setViewMode("list")}
              aria-label="List View"
              size="small"
            >
              <ListIcon />
            </Fab>
            <Fab
              color={"default"}
              onClick={() => setViewMode("kanban")}
              aria-label="Kanban View"
              size="small"
            >
              <KanbanIcon />
            </Fab>*/}
          </Typography>
        </>
      );
    };

    const renderActionButtons = () => {
      const isAnyRowSelected = checkboxSelected !== 0;

      return (
        <>
          <DeleteButtonCustom
            selectedRowId={selectedRowId}
            checkboxSelected={checkboxSelected}
          ></DeleteButtonCustom>

          <Button
            color="success"
            sx={ToolbarButtonStyle}
            onClick={handleOpenDrawer}
          >
            AGGIUNGI +
          </Button>
        </>
      );
    };

    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value); // Aggiorna il termine di ricerca
    };

    return (
      <GridToolbarContainer
        sx={{
          padding: "20px 10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "#fff",
        }}
      >
        {/* Contenuto allineato a sinistra */}
        <div>{renderHeading()}</div>

        {/* Contenuto allineato a destra */}
        <div style={{ display: "flex", alignItems: "middle" }}>
          <GridToolbarQuickFilter sx={{ marginLeft: 1, marginRight: 1 }} onChange={handleSearchChange} value={searchTerm} // Lega il valore dell'input allo stato
          />

          <GridToolbarExport sx={ToolbarButtonStyle} />
          <GridToolbarColumnsButton sx={ToolbarButtonStyle} />
          <GridToolbarFilterButton sx={ToolbarButtonStyle} />
          <GridToolbarDensitySelector />
          {/*           <GridToolbarExport sx={ToolbarButtonStyle} /> */}
          {renderActionButtons()}
        </div>
      </GridToolbarContainer>
    );
  };

  const handleConfirmApprove = async () => {
    const { rowId, status } = confirmDialog;
    try {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };

      const response = await axios.post(
        process.env.REACT_APP_APP_ENDPOINT + "api/auth/editHouseStatus",
        { id: rowId, status: status },
        { headers: headers }
      );

      if (response.status === 200) {
        // Aggiorna lo stato del componente con i nuovi dati

        // Aggiorna la riga corrispondente con il nuovo stato
        const updatedRows = rowsData.map((row) => {
          if (row.id === rowId) {
            // Assumiamo che 'status' sia il campo da aggiornare
            return { ...row, status: status };
          }
          return row;
        });

        setRowsData(updatedRows);

        toast.success("Stato aggiornato con successo");
      } else {
        throw new Error("Errore nella risposta API");
      }
    } catch (error) {
      console.error("Errore durante l'aggiornamento dello stato:", error);
      toast.error("Errore nell'aggiornamento dello stato: " + error.message);
    } finally {
      setConfirmDialog({ open: false, rowId: null, status: null });
    }
  };

  const handlePaginationModelChange = (model) => {
    setPaginationModel(model); // Aggiorna il modello di paginazione quando l'utente cambia pagina o pageSize
  };


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ margin: 3, paddingBottom: 0, border: "1px solid #004157" }}>
            <CardContent
              sx={{
                padding: 0,
                "&:last-child": {
                  paddingBottom: "0px", // Sovrascrive il paddingBottom per l'ultimo elemento
                },
              }}
            >
              <DataGridPro
                loading={loading}
                rows={rowsData}
                columns={columnsWithEditIcon}
                loadingOverlay={true}
                getRowId={(row) => row.id}
                initialState={{ pinnedColumns: { right: ["actions"] } }}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                pagination
                paginationModel={paginationModel}

                rowCount={rowCount} // Numero totale di righe
                paginationMode="server" // Imposta la modalità di paginazione lato server
                onPaginationModelChange={handlePaginationModelChange} // Gestore per le modifiche di paginazione

                rowHeight={40}
                checkboxSelection
                onRowSelectionModelChange={handleSelectionChange}
                disableRowSelectionOnClick
                disableDensitySelector
                sx={{
                  width: isSmallDevice ? "100vh" : "auto",

                  minHeight: "80vh",
                  overflowX: "auto",
                  border: "none",
                }}
                componentsProps={{
                  noRowsOverlay: {
                    // Aggiungi ulteriori messaggi o logica di fallback
                    message: "Nessun dato disponibile, verifica la connessione",
                  },
                }}
                components={{
                  Toolbar: CustomToolbar,
                  NoRowsOverlay: CustomNoRowsOverlay, // Componente personalizzato per i record vuoti


                  NoResultsOverlay: () => (
                    <Stack
                      height="100%"
                      alignItems="center"
                      justifyContent="center"
                    >
                      Local filter returns no result
                    </Stack>
                  ),
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Dialog open={deleteConfirmDialog.open} onClose={handleCloseDialog}>
        <DialogTitle id="alert-dialog-title">
          {"Conferma Eliminazione"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sei sicuro di voler eliminare questa riga?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annulla</Button>
          <Button onClick={handleConfirmDelete} autoFocus color="error">
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmDialog.open} onClose={handleCloseDialog}>
        <DialogTitle id="alert-dialog-title">{"Conferma Azione"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sei sicuro di voler procedere con questa azione?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annulla</Button>
          <Button onClick={handleConfirmApprove} autoFocus>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        sx={{
          // 👇 Another option to style Paper
          "& .MuiDialog-paper": {
            borderRadius: "0px",
          },
        }}
      >
        <DialogTitle>
          <>Crea un nuovo {currentFormType}</>
        </DialogTitle>
        <DialogContent>
          <DynamicWizard
            objectType={currentFormType}
            onClose={handleCloseModal}
            reloadRecords={getJsonField}
          />
        </DialogContent>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>

      <Dialog open={editDialogOpen} onClose={handleEditDialogClose}>
        <DialogTitle>Richiedi Modifiche</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={checkboxState.checkbox1}
                onChange={handleCheckboxChange}
                name="checkbox1"
              />
            }
            label="Dati anagrafici"
          />
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={checkboxState.checkbox2}
                onChange={handleCheckboxChange}
                name="checkbox2"
              />
            }
            label="Documentazione"
          />
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={checkboxState.checkbox3}
                onChange={handleCheckboxChange}
                name="checkbox3"
              />
            }
            label="Foto o video"
          />
          <TextField
            margin="dense"
            id="modificaText"
            label="Dettagli Modifica"
            type="text"
            fullWidth
            variant="outlined"
            value={modificaText}
            onChange={handleModificaTextChange}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose}>Annulla</Button>
          <Button onClick={handleEditDialogSubmit}>Invia</Button>
        </DialogActions>
      </Dialog>

      <Drawer
        anchor="right" // Questo posiziona il drawer a destra
        open={isOpen}
        onClose={onClose}
        sx={{
          zIndex: 1300, // Aumenta questo valore secondo necessità

          "& .MuiDrawer-paper": {
            maxWidth: "600px",
            width: "100%", // Usa l'intera larghezza disponibile fino a un massimo di 400px
            zIndex: 1300, // Aumenta questo valore secondo necessità
          },
        }}
      >

        <AppBar position="static">
          <Toolbar>
            <Typography variant="h5" sx={{}}>
              Crea un nuovo{" "}
              {currentFormType.charAt(0).toUpperCase() +
                currentFormType.slice(1)}
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{ width: "auto" }}>
          {/* Puoi specificare una larghezza fissa qui se necessario */}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <div style={{ padding: 2 }}>
            {" "}
            {/* Aggiungi padding per il contenuto interno */}
            {/* Il componente DynamicFormComponentModal rimane invariato */}
            <DynamicFormComponentModal
              heading={currentFormType}
              onCloseModal={onClose} // Assicurati che handleCloseModal sia definito correttamente o utilizza direttamente onClose
              objectType={currentFormType}
              onClose={onClose}
              updateData={getJsonField}
              prefilledValues={{
                owner_id: general.userId, // Assicura che general.userId sia definito correttamente
              }}
              hiddenFields={{ owner_id: true }}
            />
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default List;
