import React, { useState, useEffect } from "react";
import axios from "axios";
import FieldsRenderer from "./fields-renderer";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputLabel,
  Button,
  Box,
  Modal,
  Typography,
  Avatar,
} from "@mui/material";
import dayjs from "dayjs";

import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import {
  Edit as EditIcon,
  AccountCircle as AccountCircleIcon,
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AutocompleteJSONFake from "./customInputs/AutocompleteJSONFake";
import AutocompleteFakeMultiple from "./customInputs/AutocompleteFakeMultiple";
function SimpleDynamicForm({
  prefilledValues,
  objectType,
  recordId,
  title,
  showButtons = true,
  hiddenFields,
  editMode,
}) {
  const [formData, setFormData] = useState(prefilledValues || {});
  const [fieldsData, setFieldsData] = useState({});
  const [imageSrcs, setImageSrcs] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const location = useLocation();
  const currentFormType = objectType
    ? objectType
    : location.pathname.split("/")[2];
  const currentRecordId = recordId ? recordId : location.pathname.split("/")[4];
  const [isEditMode, setIsEditMode] = useState(editMode || false);

  const fetchFormData = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const endpoint = process.env.REACT_APP_APP_ENDPOINT;

      const getRecord = axios.get(
        `${endpoint}api/auth/getRecord/${currentFormType}/${currentRecordId}`,
        { headers }
      );
      const getFields = axios.get(
        `${endpoint}api/auth/getFieldsForObjectForm/${currentFormType}`,
        { headers }
      );

      const [recordResponse, fieldsResponse] = await Promise.all([
        getRecord,
        getFields,
      ]);

      setFormData(recordResponse.data);

      setFieldsData(fieldsResponse.data);
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data
        : "Errore durante il recupero dei dati.";
      toast.error(errorMessage);
      setIsError(true);
    }
  };

  useEffect(() => {
    if (currentFormType && currentRecordId) {
      fetchFormData();
    }
  }, [currentFormType, currentRecordId]);

  const handleSave = async () => {
    try {
      const modeApi = !isEditMode
        ? "storeRecord/" + currentFormType
        : "updateRecord/" + currentFormType + "/" + currentRecordId;

      const response = await axios.post(
        process.env.REACT_APP_APP_ENDPOINT + "api/auth/" + modeApi,
        formData,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );

      fetchFormData();
      toast.success("Dati salvati con successo!");
      handleSetEdit();
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data
        : "Errore durante il salvataggio dei dati.";
      toast.error(errorMessage);
    }
  };

  /*   const handleClearImage = (fieldName) => {
    // Svuota il valore dall'oggetto formData
    formData[fieldName] = "";
  }; */

  const handleSetEdit = () => {
    setIsEditMode(!isEditMode);
  };

  const handleInputChange = (fieldName, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: newValue,
    }));
  };

  const handleClearImage = (fieldName) => {
    // Aggiorna lo stato delle immagini utilizzando il nuovo array con l'immagine svuotata
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: null,
    }));
  };

  const renderFields = () => {
    return (
      <Grid container spacing={2}>
        {Object.entries(fieldsData).flatMap(([sectionKey, fields]) =>
          fields.flatMap((field) => {
            const typeField = field.type;

            const isReadOnly = Boolean(field.isReadOnly);
            if (isReadOnly) {
              return []; // Non renderizzare nulla per questi casi
            }

            if (
              hiddenFields &&
              Object.keys(hiddenFields).includes(field.fieldName)
            ) {
              return null;
            }

            const fieldValue = formData[field.fieldName];

            const formattedValue =
              // Se il valore è un oggetto Dayjs, lo formattiamo in stringa
              field.type === "Date" || field.type === "Datetime"
                ? dayjs(fieldValue).format("DD-MM-YYYY") // Personalizza il formato come vuoi
                : fieldValue; // Usa il valore originale se non è una data

            const gridItemContent = isEditMode ? (
              <>
                <InputLabel htmlFor={field.name}>
                  <b>{field.label}</b>
                  {field.required && <span style={{ color: "red" }}> *</span>}
                </InputLabel>
                <FieldsRenderer
                  field={field}
                  value={formData[field.fieldName]}
                  onChange={handleInputChange}
                  prefilledValues={prefilledValues}
                />

                {formData[field.fieldName] &&
                  (field.type === "File" || field.type === "Photo") && (
                    <Box
                      sx={{
                        position: "relative",
                        overflow: "hidden",
                        height: "100px",
                        textAlign: "center",
                        width: "100%",
                        border: "0.5px solid",
                        mt: 2,
                      }}
                    >
                      <img
                        alt=""
                        src={formData[field.fieldName]}
                        width={150}
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "100px",
                        }}
                      />
                      <IconButton
                        onClick={() => handleClearImage(field.fieldName)}
                        sx={{
                          position: "absolute",
                          top: 5,
                          right: 5,
                          width: 25,
                          height: 25,
                          backgroundColor: "white",
                          boxShadow:
                            "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
                          "&:hover": {
                            backgroundColor: "#ffffffa3",
                          },
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  )}
              </>
            ) : field.type === "Lookup" || field.type === "MasterDetail" ? (
              <>
                <InputLabel htmlFor={field.name}>
                  <b>{field.label}</b>
                </InputLabel>
                <AutocompleteJSONFake
                  value={formData[field.fieldName]}
                  field={field}
                  fieldName={field.fieldName}
                />
              </>
            ) : field.type === "MultipleLookup" ? (
              <>
                <InputLabel htmlFor={field.name}>
                  <b>{field.label}</b>
                </InputLabel>
                <AutocompleteFakeMultiple
                  value={formData[field.fieldName]}
                  field={field}
                  fieldName={field.fieldName}
                />
              </>
            ) : (
              <>
                <InputLabel htmlFor={field.name}>
                  <b>{field.label}</b>
                </InputLabel>
                <Typography style={{ overflow: "hidden", width: "98%" }}>
                  <i>{formattedValue || "N/A"}</i>
                </Typography>
                <hr />
              </>
            );
            //const gridSize = field.type === "Textarea" ? 12 : 6;
            return (
              <>
                {(isEditMode || field.type !== "File") && (
                  <Grid
                    item
                    xs={12}
                    md={field.type === "Textarea" ? 12 : 6}
                    key={field.id}
                  >
                    {gridItemContent}
                  </Grid>
                )}
              </>
            );
          })
        )}
      </Grid>
    );
  };

  return (
    <div sx={{ borderRadius: 0 }}>
      <CardHeader
        sx={{
          padding: "10px 15px",
          color: "white",
          backgroundColor: "#023d57",
          borderTopLeftRadius: 20,
          borderBottomLeftRadius: 20,
        }}
        title={title || "Dettagli"}
        titleTypographyProps={{ variant: "h6" }}

        // Aggiungi qui altre prop che ti servono
      />{" "}
      <Card>
        {" "}
        <CardContent>
          {renderFields()}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
              ...(isEditMode && {
                // Applica questi stili solo in modalità di modifica

                bottom: 0,
                left: 0,
                right: 0,
                bgcolor: "white",
                padding: "8px",
                zIndex: 9000,
              }),
            }}
          >
            {showButtons &&
              (!isEditMode ? (
                <Button variant="outlined" onClick={handleSetEdit}>
                  Modifica
                </Button>
              ) : (
                <>
                  <Button
                    variant="contained"
                    onClick={handleSetEdit}
                    sx={{ marginRight: "8px" }}
                  >
                    Annulla
                  </Button>
                  <Button variant="contained" onClick={handleSave}>
                    Salva
                  </Button>
                </>
              ))}
          </Box>
        </CardContent>
      </Card>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box
          sx={
            {
              /* stili per la modale */
            }
          }
        >
          <Typography variant="h6" component="h2">
            {modalMessage}
          </Typography>
          <Button onClick={() => setIsModalOpen(false)}>OK</Button>
        </Box>
      </Modal>
    </div>
  );
}

export default SimpleDynamicForm;
