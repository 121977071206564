import React from "react";
import dayjs from "dayjs";
import "dayjs/locale/it"; // Importa la localizzazione italiana
import weekOfYear from "dayjs/plugin/weekOfYear";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isBetween from "dayjs/plugin/isBetween";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TextField, Box, styled } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// Configurazione dei plugin di dayjs
dayjs.extend(weekOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);

// Imposta la localizzazione italiana
dayjs.locale("it");

const StyledBox = styled(Box)({
  width: "100%",
});

const Datetime = ({ field, value, onChange }) => {
  // Controlla se il valore è valido e convertilo in un oggetto Dayjs
  const dayjsValue = value ? dayjs(value) : null;

  const handleDateChange = (newValue) => {
    onChange(field.fieldName, newValue ? newValue.toISOString() : null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
      <StyledBox>
        <DateTimePicker
          value={dayjsValue} // Assicurati che il valore sia un oggetto dayjs
          onChange={handleDateChange}
          inputFormat="DD/MM/YYYY HH:mm" // Formato italiano
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </StyledBox>
    </LocalizationProvider>
  );
};

export default Datetime;
