import React, { useState, useEffect, useRef } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import axios from 'axios';
import { TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// ... altri import necessari ...
import { styled } from '@mui/material/styles';

const AutocompleteJSONFake = ({ field, fieldName, formType, isCreate, isEdit, isShow, values, fieldErrors, onChange, value }) => {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const dispatch = useDispatch();
    const fieldRef = useRef(null);

    console.log(values)


    useEffect(() => {
        const fetchOptions = async () => {
            try {
                console.log('field', field);
                const endpoint = `${process.env.REACT_APP_APP_ENDPOINT}api/auth/getRecords/${field.relatedObject}?all=true`;
                const response = await axios.get(endpoint, {
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
                });

                console.log('response', response);
                if (Array.isArray(response.data)) {
                    let extractedOptions = [];

                    if (field.relatedObject === 'User') {
                        extractedOptions = response.data.map(user => ({
                            id: user.id,
                            label: `${user.name} (${user.email}) - ${user.temporaryRole}`,
                        }));
                    } else {
                        extractedOptions = response.data.map(item => ({
                            id: item.id,
                            label: item.title
                        }));
                    }

                    setOptions(extractedOptions);

                    const processValue = (fieldValue) => {
                        // Controlla se il valore è nel formato "idRelazione:idRecord"
                        if (fieldValue.includes(':')) {
                            // Estrae l'ID del record dal valore
                            const [, recordId] = fieldValue.split(':');
                            return recordId;
                        }
                        return fieldValue;
                    };

                    if (value) {
                        const processedValue = processValue(value); // Processa il valore per estrarre l'ID del record se necessario
                        const selected = extractedOptions.find(option => option.id == processedValue);
                        console.log('Selected option:', selected);
                        setSelectedOption(selected || null);
                        onChange(fieldName, processedValue); // Usa il valore processato
                    }

                    if (values && values[fieldName]) {
                        const processedValue = processValue(values[fieldName]); // Processa il valore per estrarre l'ID del record se necessario
                        const selected = extractedOptions.find(option => option.id === processedValue);
                        console.log('Selected option:', selected);
                        setSelectedOption(selected || null);
                        onChange(fieldName, processedValue); // Usa il valore processato
                    }

                }
            } catch (error) {
                console.error('Errore nella chiamata all\'endpoint:', error);
            }
        };

        fetchOptions();
    }, [field, value]);

    const handleSelectionChange = (event, newValue) => {
        setSelectedOption(newValue);
        onChange(field.name, newValue ? newValue.id : null);
    };

    useEffect(() => {
        if (fieldErrors && fieldRef.current) {
            fieldRef.current.style.border = '2px solid red';
        }
    }, [fieldErrors]);

    const getOptionLabel = (option) => option.label;
    const CustomTextField = styled(TextField)(({ theme }) => ({
        "& .Mui-disabled": {
            color: theme.palette.text.primary, // Testo come se fosse abilitato
            "-webkit-text-fill-color": theme.palette.text.primary, // Per Chrome e Safari
        },
        "& .MuiInputBase-root.Mui-disabled": {
            "& fieldset": {
                borderColor: "rgba(0, 0, 0, 0.23)", // Bordo come se fosse abilitato
            },
        },
    }));


    return (
        <Autocomplete


            ref={fieldRef}
            multiple={false}
            name={field.name}
            options={options}
            disabled
            renderInput={(params) => (
                <CustomTextField
                    {...params}
                    variant="standard"
                    error={Boolean(fieldErrors)}
                    helperText={fieldErrors || ''}
                />
            )}
            placeholder="Clicca per scrivere"
            getOptionLabel={getOptionLabel}
            value={selectedOption}
            onChange={handleSelectionChange}
            renderInput={(params) => (
                <TextField {...params} variant="standard" error={Boolean(fieldErrors)} helperText={fieldErrors || ''} />
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => <Chip label={option.label} {...getTagProps({ index })} />)
            }
        />
    );
};

export default AutocompleteJSONFake;
